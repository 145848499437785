import React from 'react';
import { Grid } from '@chakra-ui/react';
import { Word } from './Word';

export const CenterLogo = React.memo(() => {
  return (
    <Grid userSelect="none" templateColumns="repeat(2, 1fr)">
      <Word>则</Word>
      <Word>心</Word>
      <Word>灵</Word>
      <Word>诚</Word>
    </Grid>
  );
});
