import { HexagramMessageMap, HexagramType } from './type';

export const hexagramMessagesMap: Record<HexagramType, HexagramMessageMap> = {
  乾: {
    主要: '表示极阳之象，为纯卦、六冲卦，是大好、大坏的卦象。对事一般为吉象，为主管、负责任，可以任用担当大事，或开业当老板，得此卦都属吉象，只是必须有一个好的助理更佳，能升官、考试、升迁都较有利。',
    失物: '须一段时间，在西北方可以找到。',
    子女: '儿女健康，幸福有为。',
    家运: '幸运兴隆，须小心口舌争论之象。',
    寻人: '已远走高飞，不易找到，在西北方向。',
    爱情: '彼此之间切勿逞强，摒除自我主义的观念方可成就，但常有闹意见之情况。',
    改行: '改行者不宜。',
    旅行: '夏季不宜，勿行南、北，其他可。',
    求事求职: '有贵人提拔，可发展，称心。',
    特性: '积极，刚毅，努力，认真，有耐性，坚忍，正派，主动，慷慨，严律，重名誉。',
    疾病: '为头部或神经之疾，宜细心调养。',
    等人: '一定会来。',
    考试: '有望。',
    胎孕: '有不安之兆，宜小心保养。',
    解释: '刚健稳固。',
    讼词纠纷: '有理，据理则吉。',
    买卖: '小利可得，大则勿取，勿太张扬为吉。',
    周转: '虽不成问题，尚宜心平气和来处理。戌亥日可。',
    运势: '有地位擢升、名利双收之象，功名荣显得贵子。六亲和睦，凡事谦和为贵，可吉。',
  },
  坤: {
    主要: '表大地为母，天生之母性也。六冲纯卦。主大好大坏之卦象。优点',
    失物: '西南可寻，遗失西北难寻。',
    子女: '儿女众多，和睦幸福也。',
    家运: '幸福平静之象也。',
    寻人: '此人为色情之事出走，於西南方附近之地也。',
    爱情: '男主动，女主静，缓进可成，急则有失。',
    改行: '改行不利，宜固守本份。开业者再观望，目前尚未筹备妥当，不宜。',
    旅行: '不宜。更忌东北方。',
    求事求职: '不称意亦不理想，另寻。',
    特性: '为人较为保守，善於计画与研究工作，适合任辅佐幕僚之职，做人朴实，人缘佳，大好人型，男性桃花多，女性则爱情专一。',
    疾病: '为腹部之慢性病，病情重且危。',
    等人: '不会来，须再待些时日。',
    考试: '希望不大。',
    胎孕: '生贵子。女儿亦福。',
    解释: '在母胎里孕育中，不宜创业',
    讼词纠纷: '多因田土之是非，宜解。有始无终之兆也。',
    买卖: '勿急。交易可成。',
    周转: '须耐心去求，否则难成。',
    运势: '诸事不宜急进，退守以静制动者吉。坤者大地平静，任其他物所摧而不为所动者大利也。若醉心私慾，违背常理，则有无情之灾。',
  },
  屯: {
    主要: '表刚出生的小草。主凶象。四大难卦第一卦。因为刚出生的小草，脆弱易折，表示凡事都会很困难，很容易就会夭折、失败。但是只要有心小心地护持，小草还是有机会长成大树的！',
    失物: '若非急寻，皆不得见也。',
    子女: '有辛苦，但以後可得安逸。',
    家运: '多波折，若能努力排除困难，可得通顺。',
    寻人: '出走者不知去向，难寻。东或北方去矣。',
    爱情: '不妥协，或已暂时失去连络，否则就是出现不良状况而有所阻碍，成就有困难也。而此卦男人得利，女人失利。',
    改行: '改行不宜。开业不宜，有困难亦难成也。',
    旅行: '不宜。',
    求事求职: '不利。',
    特性: '理想远大，奋斗不懈，苦尽甘来，最後能达成目标，获得他人的肯定。一生困境多，烦恼厌烦多，朋友少助。',
    疾病: '为肾脏部位或神经系统之疾病。病或伤在手足，占病大致危险。苦二爻三爻变时，即显凶兆。',
    等人: '不会来。',
    考试: '不理想。',
    胎孕: '不安，产母有虚惊，但可无虑。',
    解释: '不明不白',
    讼词纠纷: '小事成忌而粘滞，大事反不为大吉。诸事宜解。',
    买卖: '不利，交易有损。',
    周转: '困难重重，须用心忍耐奔波，但仍不看好。',
    运势: '逆势，多有困惑，但不可妄进，宜守不宜进。坚定意志，凡事不可为一己之利而执意强行，皆有不成损破之象。宜步步为营，排除困难，始可通达。有初难後解之象。',
  },
  蒙: {
    主要: '表被蒙蔽不明之意。主小凶象。事情迷蒙不明，不宜轻信於人，此卦易犯小人，需诚心求教於有智慧经验的友人长者，受其启蒙开智慧，方能转危而安。若是一意而行必现大凶象。',
    失物: '难寻得。',
    子女: '应注重子女教养，将来方得幸福之象。',
    家运: '初时辛苦多端，上下无法沟通，只要以诚相待，必有开运之时。',
    寻人: '受诱惑而出走，因本身蒙昧无知而受骗。在东北或北方，日前难寻。',
    爱情: '此卦为未启蒙之卦，致落花有意而流水无情。主要是缺乏勇气与决心，致不成事也。若有成，此卦亦主劳碌耳。',
    改行: '改行不宜。开业者不宜。再等一段时间，筹备好资金再做打算。',
    旅行: '不利。',
    求事求职: '未能如愿，再等时机。',
    特性: '喜欢追求新的知识，过平稳生活，做事谨慎，对下属要求较严，领导统御略差。宜防受朋友之拖累蒙蔽。',
    疾病: '由得此卦幼时多病，中年後渐强壮。占病者，病情不明，且为反覆之病症。为脾胆、肠炎症或下腹疾病。急无功，须长治。',
    等人: '可能走错路，会迟到。',
    考试: '不理想。',
    胎孕: '胎孕无碍。',
    解释: '被蒙蔽、被欺瞒 。',
    讼词纠纷: '因外人引起，目下末见明白，有理亦讲不清，故有凶象。久而不成。',
    运势: '气运不发，多迷惑。初时不顺，须忍耐待机，渐可佳。切勿贸然行事或为人认保、作保，否则必有损失。凡事宜从上辈教诲，则运可通也。',
  },
  需: {
    主要: '表需要、需求，但却又是时机未到而着急着。此卦凶中带吉，需耐心等待，则事情可成。健康，要特别注意，此卦为「游魂卦」。',
    失物: '因喜乐中有失，隔些时日可寻，急亦难如。',
    子女: '得子迟。',
    家运: '初为多事之秋，须凭智慧耐心挽救颓势。',
    寻人: '此出走之人因感情之事，於西北或北方。',
    爱情: '起初不如意，有耐心者方可成就，否则不成也。',
    改行: '改行不宜。开业亦不宜。',
    旅行: '不可。有盗难之兆。',
    求事求职: '不宜急取，急亦不得，再等待，再寻求。',
    特性: '谦恭有礼，性格保守，稍被动，但为人诚恳，慾求不高，中晚年才渐入佳境。不利早婚，有宗教艺术兴趣。',
    疾病: '为头部、肾部之疾，病情拖延而长。须留心治疗，长久病疾也。',
    等人: '迟到。',
    考试: '要多用功。',
    胎孕: '临产之时得才占卜表会有障碍。防克产母。',
    解释: '需要等待时机 。',
    讼词纠纷: '目下未了，但最好停止纷争。',
    买卖: '有口舌不可成就，亦勿贪小而失大。',
    周转: '不能预期而得，有延滞。',
    运势: '宜退守正道，不宜冒险前进，投机急取反而失利。须知贪小失大，智者必须待时也。',
  },
  讼: {
    主要: '表诉讼、争端之象。主大凶象。事情冲突必须到见官上法院，可能必须以法律途径来解决了！凡事皆不利，而且已引起很大口舌是非争持，而必须摊牌互相攻击的程度了！',
    失物: '因左右之人而失，不必找了。即使找到，亦必有争端，不如破财消灾。',
    子女: '父子之间存有歧见，无法沟通之象。子女生来劳碌，与父母缘薄，多辛劳忧苦也。',
    家运: '家庭内部不和，有口舌争端之害，并有散离之象，力持仁与和，运破败，离散之凶象也。',
    寻人: '此人因争执事由负气出走，并有生命危险，又寻找不易，尽量往西北与北方寻找。',
    爱情: '多事之时，是非频频，受中伤不易成也。',
    改行: '改行不利，开业不宜。',
    旅行: '不宜。',
    求事求职: '难成。',
    特性: '不服输，好竞争辩论，反应快，口才佳，易得罪他人。自立自强之人。',
    疾病: '除了尽量医治外别无他法。所患为脑部、外伤头部、肾脏与血液循环系统之疾病，多病之象。',
    等人: '不会来。就算会来，双方必有争执情事发生。',
    考试: '不理想。很差。',
    胎孕: '生产无惊阻，安也。',
    解释: '官司缠讼。',
    讼词纠纷: '不利。两败俱伤。最好由中间人调解，再争无益也。',
    买卖: '因处理不当，有冲突争端，常招损失，不利也。',
    周转: '不得要领，难成。',
    运势: '百事闭塞不通，伤害频繁，受中伤而又易陷於奸计，不如意之运也。得饶人处且饶人，得过且过，莫再争讼，否则皆不利也。',
  },
  师: {
    主要: '表军队打仗强烈抗争之意。主大凶象。师亦为「师长、老师」，是为强烈教训的现象，若在事、在人，均表示会受到很大教训、挫折。或是与人结怨甚深而遭到其强力的打击、迫害。',
    失物: '已被窃走。',
    子女: '大出息之象。',
    家运: '外柔顺而内险恶，故家内常生不和，需慎重处理。行正道可化险为安。',
    寻人: '此人因家内失和而出走，速往北方或西南找，否则有危险。',
    爱情: '慎防感情引起的冲突、伤害，难再有成。',
    改行: '改行不宜。开业者须待时机行事，勿过急。',
    旅行: '不利。防有盗失。',
    求事求职: '独立无望，有人扶持则可。',
    特性: '讲求实力，具有毅力，不怕困难，挑战艰钜任务，虽然时常会遇到逆境，都能一一化解克服，赢得他人的赞许。',
    疾病: '为腹部之恶性肿瘤，非绝症。若超年久病即有活解。',
    等人: '来者不善。',
    考试: '很认真，但不理想，继续努力终有成就。',
    胎孕: '喜中有忧。或有不安或不孕。',
    解释: '但要师出有名。',
    讼词纠纷:
      '有贵人为福，不宜退，他人无气有忧危，终有利。但形成硬碰硬，多因之局面也。',
    买卖: '有阻，不太顺意，寅午戌日交易可成。',
    周转: '无望。另觅别处。',
    运势: '事虽有不顺、但要引以为镜，记取教训。不要图私利而投机取巧。凡事应以正规而行，事物多变动，切忌独立而行。困难多端，靠自己努力，摒除一切困难为上策。',
  },
  比: {
    主要: '表兄友比肩而站，一片融合互持、比和之意。主吉象。水溶入土中、胶和在一起，是个很亲密的卦象，尤其是论男女之情。事业投资有亲密的伙伴相助，共同扶持打拼，事事当然越佳顺利进行。',
    失物: '东北、西南方可寻，但有损减。',
    子女: '儿女温顺且侍亲至孝，亲情融合之象。',
    家运: '家庭内部不和，有口舌争端之害，并有散离之象，力持仁与和，运破败，离散之凶象也。',
    寻人: '不在杏花处，则是有妇人相留，不可寻，恐生不测之祸。此人会自己回来。',
    爱情: '合家和乐，家运亨通。宜处事有序使运吉。',
    改行: '改行有利，可得友人支持。开业者可大发利市，但须注意有人背叛。',
    旅行: '吉利。',
    求事求职: '有贵人，可有利。',
    特性: '勤劳，认真，善良，谦恭，务实，踏实，重生活享受，品味人生，安排娱乐。异性缘多，感情烦恼也多。',
    疾病: '为腹部反耳痛之症，或肾脏下部之疾，近病者速医可治。',
    等人: '会来。',
    考试: '金榜题名。',
    胎孕: '孕安。',
    解释: '平等、比合。',
    讼词纠纷: '宜和，有小人。以和顺态度处理可调。',
    买卖: '莫太贪心。交易反覆难成，勿贪则有利可成。',
    周转: '必成、找同年之之兄弟、朋友可成。',
    运势: '运气平顺，与人相亲处事则吉。可获贵人提拔，但不可得志而非份横行急进，否则遭破逊也。',
  },
  小畜: {
    主要: '表小有积蓄。是个小吉卦，事事不可贪大，投资理财均需保守为佳。健康无碍平顺。',
    特性: '上进心，求知慾强，思想灵敏，察言观色，善解人意，追求精神层次，美好事物。有艺术美学方面天分。',
    解释: '稍有积蓄。',
    运势: '有蓄聚、阻止之意。一阴畜五阳，虽有云雨而不下，雨之不下，大地不能得到滋润，故此运势有反覆、有口舌、又难以急调，诸事宜忍耐，等待时机。尤其女人得此卦更应在行为上，对事或对人有所节制。',
    爱情: '语言不和，意见分歧，不太如意。',
    家运: '不和且多是非，衰而不振，多劳，要耐心应对，以待时机。雨过天必晴。',
    胎孕: '宜防流产。',
    子女: '因子女而劳碌之象，但晚年有福也。',
    周转: '有女人从中阻碍，不成也。',
    买卖: '景气不好，买卖难成或利薄。',
    疾病: '患者有胸部、胃肠等疾。其病难治但不严重。',
    等人: '不会来，临时变意。',
    寻人: '因感情或家庭不和出走，只在原处。东南或西北之向。',
    失物: '被小人侵算，有妇人见得，急向东南方觅之。屋内之失物可寻。',
    旅行: '途中有害，不宜。',
    讼词纠纷: '一不成亦和。一惹祸带刑惕，不利。',
    求事求职: '希望渺小。',
    考试: '不理想。',
    改行: '改行不宜，开业不宜，已开业者则困难、口舌多。',
  },
  履: {
    主要: '表战战兢兢、如履薄冰而行，却是有惊无险。为小凶带吉之象。履又为「礼」也。得此卦者，需尽快反省自己的态度，对人、对事、对长辈是否有轻忽、不敬之意。虽有危急之事幸好能平安度过。',
    特性: '竞争，积极，冒险，热枕，独立，自立自强，有骨气。易给人反感，嫉妒。',
    解释: '只要履行契约承诺则吉。',
    运势: '有先劳後逸、始惊後安之意，虽有繁华，却又反覆不和，故得此卦时为忧虑受危中而望喜渺。但谦虚反省请教於长者则吉。',
    爱情: '相爱，但阻碍很多，虽然不易成功，但亦有以诚、礼相待而成婚姻者。',
    家运: '新婚而刚建立家庭者，多碍、劳苦。但此卦有先苦後甘之象，和气可生财，过些时日即可雨过天晴也。',
    胎孕: '临盆产妇占得则吉，否则有受惊吓之象，需安胎。',
    子女: '初运有劳苦，而且障碍、困难之象，但以後能获得幸福也。',
    周转: '虽有些困难，但不必急躁，谦恭而施则可成也。',
    买卖: '虽有是非但终可成，牛马猴之月或日方成。',
    疾病: '得此卦者，幼年体弱，中年後渐佳。断疾病为肺部、头部或口部之病，病况虽似严重而危险，但调理得宜，治疗後可痊癒。',
    等人: '迟来。',
    寻人: '走失之人有生命危险，在西方或西北方向，难寻，须争取时效，此人尚可得救。申未日可见。',
    失物: '须一段时间，在西北方可以找到。',
    旅行: '夏季不宜，勿行南、北，其他可。以占卦之时断之。',
    讼词纠纷: '有理，据理则吉。',
    求事求职: '有贵人提拔，可发展，称心。',
    考试: '有望。',
    改行: '改行者不宜。',
  },
  泰: {
    主要: '表三阳开泰，万事亨通。吉卦之象。诸事皆顺。',
    特性: '交游广泛，包容性大，有雅量，个性开朗，乐观，积极，主动。又能接受他人意见，心胸广阔。',
    解释: '否极泰来，鸿运当头。',
    运势: '诸事如意吉祥，前途事业均顺利。切不可骄傲或任意从事，亦宜自惕勿太活跃，始能免於灾难。凡事宜求内在之实，不求外在之虚，否则有破。',
    爱情: '情投意合，良缘。勿任性，以维持吉象，反之则招破。',
    家运: '家庭和合，有通亨之象，凡事宜检点得失，不可胡为，否则招灾不利。',
    胎孕: '生贵子。安而无灾。生女亦均无碍。',
    子女: '亲子和睦，幸福圆满。留心教养，勿使太任性，否则沦於不幸之破运。',
    周转: '可成。',
    买卖: '交易有益，得利。',
    疾病: '为胃肠、或重感冒、肺、头痛之症，无大碍。',
    等人: '会来。',
    寻人: '在朋友或亲戚家里，有信息也。可寻。',
    失物: '非是人偷、自己遗失。有可能寻回之象。',
    旅行: '顺利平安。',
    讼词纠纷: '因小意见而引起，不宜见官，和解有利。',
    求事求职: '吉利亨通。',
    考试: '上榜有望，宜再努力勿懈。',
    改行: '改行吉。开业者吉利之象。',
  },
  否: {
    主要: '表不好、坏掉了！主凶象。否显现出阴阳之气不协调，在人及事方面诸多不被认同，有内外不合、众说纷纭的现象，因此事事难以进行，最好此时能彼此互相退让静下心来理性的沟通，方能转吉。婚姻、感情，已有个性、意见的冲突产生，若不能好好退让协商，恐有离异分手的结局。',
    特性: '自尊心强，个性刚烈，不服输，反应快，学习能力强，高傲不亦亲近。财运佳，有领导才能，与上司不易相处。',
    解释: '处在困境中。',
    运势: '凶且衰，君子以险德避难，不可荣以禄也。迩势不通达，诸事不顺，有损失且波折多。故宜守固正道，若稍偏差，则灾害至也。',
    爱情: '阴阳相背，被拒千里之外。',
    家运: '夫妻彼此不能协调，是非很多，劫财又带衰，慎之。',
    胎孕: '不安，爻神无气。',
    子女: '与双亲感情不睦。任其自然发展吧。',
    周转: '告贷无门。甫求了。',
    买卖: '多有损失。但有贵人应在午未申日。',
    疾病: '占病凶兆，康复无望，反象也。病灶为脑部、胃部、癌之类。',
    等人: '不会来，因别的事情而违约。',
    寻人: '因感情不睦而出走，去向不明，难寻。东南方。',
    失物: '热闹中遗失，东南方寻之。',
    旅行: '不宜。',
    讼词纠纷: '有口难言，有虚伪，意见不能妥协，此时宜求顺。',
    求事求职: '先难後易，须有耐心去争取胜利，否则凶。',
    考试: '不理想。',
    改行: '改行难如愿，开业最好另择吉日。然此卦有先难之困，若能舒之则利。',
  },
  同人: {
    主要: '表有志一同，相和而成。主吉象。理念相同共处愉快。事业投资、感情皆是和睦融洽。最适合找人合夥事业或寻求认同自己理念的吉卦。',
    特性: '喜团体生活户外活动，有领导才能，善交际，人际关系佳。有幽默感。',
    解释: '结合志同道合的人集思广益。',
    运势: '得此卦为诸事开通、平安、吉祥、如意之象，有同情心，互爱、亨通之象，故宜好好掌握时机，维持盛泰。若有稍涉偏私或为私情之爱则有破兆，应以事业为重。',
    爱情: '互悦其情，彼此间谦和以礼互敬，鱼之得水，定成美眷。切忌气盛，宜和。',
    家运: '上下和乐，运势亨通，可得意外之财，亦可促进开展事业之枢机，宜把握良机，努力求取。',
    胎孕: '无碍。与六亲和睦。得缘之吉卦。',
    子女: '上下皆能和睦之象。',
    周转: '顺利，并可进取大业，利也。',
    买卖: '利益大，与人共事吉，宜木字口字姓之人同相利济更吉。',
    疾病: '虽有病，但需是动爻而论。为脑部、神经衰弱或呼吸之疾病，心神恍惚、失眠、心火气旺之象，宜多调养、修持参佛。',
    等人: '一定会来，且会带来好消息。',
    寻人: '此人非故意出走，自己会回来。',
    失物: '很快可以找到。',
    旅行: '利，放心。',
    讼词纠纷: '与人争端无益，他侵我或我伤他皆不利。和解了事为上策。',
    求事求职: '吉利。',
    考试: '成绩优良。',
    改行: '改行、开业者均吉利亨通。',
  },
  大有: {
    主要: '表有很多的收获。是个吉卦。易经解释大有卦为，春耕、夏耘、秋收、冬藏，是个努力坚持之後的愿望期待实现。很利於投资、合夥、感情、婚姻。',
    特性: '具有组织能力，能扩展大事业，吸收各方专才，克服困难，有努力完成大目标勇气，荣誉心重。',
    解释: '旺盛大有收获。',
    运势: '得时得运，隆盛昌荣也。然卦象藏有盛极必衰的预兆，不得不慎，以期保持其全盛之枢纽，可众望所归。有走红、赚钱之势，须谨慎不可骄纵。',
    爱情: '可实现理想之吉象。',
    家运: '生平富贵吉利。切勿因富而凌贫，过於傲气凌人者不利也。',
    胎孕: '生贵子女，宜往大医院。古人云',
    子女: '儿女有成，父母欢欣之象。',
    周转: '大有所得。',
    买卖: '交易有获大利之象，但亦宜审慎行事。',
    疾病: '为高血压、肺部等疾，治疗後可无碍。然其病须注意，反态则凶。',
    等人: '会带来好消息。',
    寻人: '此人为感情或金钱之事出走，宜速找寻，否则恐有走极端之险。以东南再南方再西北三向寻之。',
    失物: '於南方或西北方之高处寻之，可寻。',
    旅行: '可。',
    讼词纠纷:
      '大事可成，小事有头无尾，木字人是贵人，异性之人有惊，可得圆满解决。',
    求事求职: '顺利可行。',
    考试: '科甲明登。',
    改行: '可以依计划进行。开业吉利之时。',
  },
  谦: {
    主要: '表谦虚不出风头之意。主吉中带小凶。谦卦显示除了谦虚不与人争之外，还有一个很重要的意义，就是被「压抑」！在目前到处都充满竞争的世代里，彼此竞争、排斥，是很正常的一种现象，所以，谦卦就是告诉你，虽然一时被压制了，但是忍辱负重，很快就会再意气风发的。因此不要气馁、灰心。另外，也显示，事事不可强出头，目前局势下，谦让不意气用事，才可得吉象。',
    特性: '待人谦恭，随和，交游广泛，喜休闲活动，重视生活安排及财物的规划。并不断追求新知，配合时代脉动。',
    解释: '要谦虚才可得到助力。',
    运势: '吉利平安，欣欣向荣之象，谦者前途大利，骄者横行招败。故以君子谦德，纵涉大川而无险也，卦利君子。正所谓谦受益满招损也。',
    爱情: '以谦恭相追求有成，可获良缘，反之失利。',
    家运: '幸福之吉运。',
    胎孕: '生男儿无灾。生女儿亦无灾。',
    子女: '子女多温顺，事亲孝，可贺也。',
    周转: '诚实谦恭，可成也。',
    买卖: '终成，有是非。获薄利，益也。',
    疾病: '为腹部与头部之疾，或有旧病复发。可癒。',
    等人: '准时到达。',
    寻人: '未见，待一段时间自回。',
    失物: '东北或西南失之可寻，东南失之难求。',
    旅行: '可。',
    讼词纠纷: '有官事，拖延不利，宜速和解。',
    求事求职: '吉利。可成。',
    考试: '金榜题名。',
    改行: '改行可行。开业者吉利，但诸事勿太急，以谦则受益。',
  },
  豫: {
    主要: '表高兴悦乐之意。主小吉象。此卦显示做事积极、有力，性情柔和、圆融，充满令人喜乐之气象。凡事可尽力去做，无大碍。婚姻、感情，男建、女欢，可得良缘。',
    特性: '稳重，有组织领导的能力，心地善良乐於助人，做事积极务实有效率。精於计画，有金钱观念。',
    解释: '顺应天命。',
    运势: '此卦象徵万物欣畅，如意安泰，可得上辈之助。切不能因繁华而怠惰，或沉醉於声色欢场之中。好运不常有，当好好把握。',
    爱情: '一帆风顺，天赐良缘。',
    家运: '吉象。切不可迷於情而招破相。',
    胎孕: '孕安。',
    子女: '儿女均能相亲相爱。前程美好之数。',
    周转: '稍有阻，但可顺利。须诚。',
    买卖: '好时机。秋更吉，有贵人。',
    疾病: '肝脏、腹内之疾。',
    等人: '途中有碍，一定会来。',
    寻人: '东方或西南方可寻。或他人自来相寻。',
    失物: '速寻，否则难得。',
    旅行: '可以。',
    讼词纠纷: '有头无尾，有震动惊恐，亦有别人连累，破财。',
    求事求职: '可得上辈提拔，有希望。',
    考试: '金榜题名。',
    改行: '改行吉，大有可为。开业者大吉大利之象。',
  },
  随: {
    主要: '表随遇而安，一切随缘、随和。主吉象。事事均可依随着自己的心思计画来执行，而会顺利地达成。事业、投资、理财、感情，均会有令人满意的发展和收获。',
    特性: '交际佳，贵人运多，善谋略，为辅佐良才，保守，被动，萧规曹随。',
    解释: '跟随上司主意行事。',
    运势: '物事均有去旧迎新之吉象，凡事与他人互相通达、协商，可名利双收。倘若三心二意，或独立单行，不听人言劝，有自招灾祸之虞。',
    爱情: '目前重於情慾、经济，依赖心较重，顺着时事、心性而行即可，感情如家人般的结合。',
    家运: '本身有相当不错的收入，富有之象。但不可过於放纵，守操节为宜。',
    胎孕: '无碍。无惊险。',
    子女: '亲情融洽，与六亲和睦，幸福之象。',
    周转: '和气相商有利，意气用事则难成。',
    买卖: '勿顽强固执，或不采纳别人的意见，则交易有利。否则失之，有阻。',
    疾病: '占病凶，须长期治疗。亦须注意口部、呼吸系统肺部及肝脏之毛病。吉凶需以动爻、变卦来判断。',
    等人: '会迟到。',
    寻人: '不必去找了，过些时日会自己回来，勿急。',
    失物: '附近之东方或西方，混杂於某些物品中，可寻。或自己误失，可急寻。',
    旅行: '吉利。有人随行更好。但此卦亦须防单独外出，小心无谓之灾。',
    讼词纠纷: '日前无忧，不久有，互有意见。和解为宜。',
    求事求职: '虽吉，然若能谦逊待人，多接受别人意见，会得人提拔。',
    考试: '成绩理想。',
    改行: '改行有充份计划者可行。开业吉利。',
  },
  蛊: {
    主要: '表受到蛊惑、毒害。主大凶象。蛊毒是因内在腐朽而生虫化毒，显示问题的根因存在已久，却未诚实面对处理而导致愈来愈严重。若是动爻在外，则表示被外来的小人所害，其怨恨根结甚深。易做出错误的决策，慨大都被人所迷惑、而一时心神错乱引致祸事。',
    解释: '中蛊毒，无可救药。',
    运势: '诸事不如意、积弊已深，未能进展，气运杂乱。逢此宜鼓起勇气，大胆加以革新，有内忧外患之象。',
    爱情: '关系复杂，尤其男人到处留情，苦恼多，终会一刀两断。另觅对象可吉。女子若是再婚者，其前夫不良，且已断缘再嫁。',
    家运: '家庭易生烦心之事，不彻底改革有破家、损财之象。',
    子女: '双亲不堪其忧之苦象耳。',
    周转: '信用不好，周转无望。',
    买卖: '交易不成，另谋生计为要。',
    疾病: '为内脏恶性疾病。冬季占得险象也。',
    等人: '中途有变，不会来。',
    寻人: '因家庭感情失和而出走，或与人私奔，於东南或东北。',
    失物: '难寻。',
    旅行: '有险。不宜。',
    讼词纠纷: '不易解决，会拖一段时间。',
    求事求职: '不利。',
    考试: '落榜。',
    改行: '开业者不宜，有损财或官符之灾。',
  },
  临: {
    主要: '表大驾光临，贵人来到之意。主吉象。临又为「君王临幸」之意，是上者对下者之象，在事业投资，为贵人相助之意。在感情则有期待而至的欢喜感。只是以上之象皆有，人尊己卑需仰息於人的感觉。',
    特性: '待人热心，心胸开阔，乐於助人，尊重他人，体会别人心意，谦恭有礼。自然贵人多来帮助，财运丰厚。',
    解释: '好事即将来临。',
    运势: '运势渐增，诸事亨通如意，前途有望，仕途有成，可得贵人相助。上下安合。以和顺可奏大功，宜诚心待人，可诸事顺畅，急进或忘形者，有破财之象。',
    爱情: '幸福圆满，切勿意气用事，否则有反。',
    家运: '家庭内部不和，有口舌争端之害，并有散离之象，力持仁与和，运破败，离散之凶象也。',
    胎孕: '生贵子。求佛及家神则安。产妇慎饮食。',
    子女: '得孝顺之儿女。',
    周转: '可成。',
    买卖: '防口舌，勿过急，和顺交易有利。',
    疾病: '为胃病，泌尿生殖系或腹部、口腔之疾，不严重。',
    等人: '有好消息且准时到。',
    寻人: '西方或西南方，不必急，目下其人已动。自回。',
    失物: '在灶下或水边，迟找即空。',
    旅行: '吉利。',
    讼词纠纷: '三人之事，本欲欺他人，反伤于己。有贵人主和，或莫再争。',
    求事求职: '顺利可成。',
    考试: '金榜题名。',
    改行: '改行吉利。开业正得其时，大吉大利。',
  },
  观: {
    主要: '表观察、观看。是个最标准的状况卦，吉凶未定。凡事不可轻下决定，需要再观察一下局势再做打算。尤其是对长官、上位者的态度更是要察言观色，不可轻忽大意。',
    特性: '有创意，喜欢开发新事物，见解新颖，爱旅行，观摩他人，研究比较。不断修正反省自己。内心易有即墨感。',
    解释: '尚在观察中。',
    运势: '处在有危险不稳的时运，耍多观望时势之利弊，待机行事，於人事物，须以诚待之，才能感化而幸免陷入困难也。',
    爱情: '外表看好，内则虚浮，有上辈提携可望达成，否则尚处在漂动不稳定的状态。',
    家运: '正处於下坡时段，要知固守进退之机，听取长辈意见有助也。',
    子女: '儿女将来有成就幸福耳。',
    周转: '虽有好言，实际内在空虚。无指望居多。',
    买卖: '交易有碍。应在戌亥日可成，否则难成。',
    疾病: '为腹部、神经系统之病痛，病情怪异且有变动，宜妥为治疗。更需审慎再加检查详细，不可随意断症。',
    等人: '观望必有等待，而来者有随之他去之象。',
    寻人: '此人到处漂泊，行踪不定，有险象。目前在西南方，宜丑未日见。',
    失物: '难寻。',
    旅行: '不宜。',
    讼词纠纷: '虽然终会和合，但有小人在内，防亥日有武动。愈争论纷争愈多。',
    求事求职: '不易。',
    考试: '不理想。',
    改行: '擅自主张不宜，若由他人提拔则可。开业者不宜。',
  },
  噬嗑: {
    主要: '表如鲠在喉、难以决策。主吉凶未定，是个状况卦，有点偏小凶。也如同「鸡肋」一般，食之无味、弃之又可惜！是需要坚决下个决心的时候了！',
    特性: '生命力强，坚守奋斗，百折不挠，要求自己很高，不断充实自己，突破超越提升。克服困难，宜往大企业大单位任职，较能有所表现。',
    解释: '被吞没掉。',
    运势: '诸事不遂心，多受阻害。有纷争，无法前进之时，宜常守原则，不为利诱，以免被人中伤。然而一般人多有无法逃避利诱者，故必受祸，若能和气处事为安。更须突破困难方可通达。',
    爱情: '有被人阻碍或横刀夺爱之事。但勿气馁，勇往迈进即可成功，否则放弃罢了。',
    家运: '家庭有不和现象，亦常有无法沟通之阻碍。除非彼此能够排除成见，否则无法融洽相处。',
    胎孕: '胎有惊。',
    子女: '儿女倔强、反抗。',
    周转: '卦已明示有困难，但诚心或可圆满。',
    买卖: '多阻挠且多是非。但若积极推展，不要放弃，则有利，可成。',
    疾病: '可能有神经方面、心脏、足部及肿疡之症。',
    等人: '受到阻碍，不能来。',
    寻人: '必因发生争端或涉嫌重大事件而出走。去庙观市井求之，东、南方。',
    失物: '失此物而有二人争论之数。可寻束、南方，或夹在某物品中，一时不易察觉。',
    旅行: '不宜。',
    讼词纠纷: '非必要时，宜采取强硬措施。诉之则可得解决。',
    求事求职: '多生枝节，不利。唯有积极去谋求方有所成就。',
    考试: '不佳。',
    改行: '改行有碍，但耐心排除可成。开业吉，中途有是非，宜耐心解决。',
  },
  贲: {
    主要: '表美丽得外表装饰。主小凶象。「金玉其外、败絮其中」最佳解释。经过有心修饰、伪装的外表或事情，事业投资、感情皆不宜。慎防有心的小人、伪君子。',
    特性: '重精神，轻物质，有艺术文学专才，感情敏锐，追求理想。在情爱上，易留下刻骨的回忆。',
    解释: '表面好看、里表不一。',
    运势: '卦象是象徵其人外表好看，内在空虚，因此，必须充实自己，凡事深思远虑，与人和睦相处，能获得意外利益。切忌因小失大，更无须为了掩饰外观而造成不必要的损失。凡事踏实，按部就班为是。',
    爱情: '不可自视太高，也勿把对方及自己都估计太过，须知华丽的外表并不能决胜负，宜相诚以对，若自视太高反不得所爱。宜慎。',
    家运: '外美内虚而不为外人所知，应即时整顿家庭经济，使其安稳、充实，更须安份自持，莫以虚浮来掩饰空虚。踏实为上策。',
    胎孕: '孕有不安之象。',
    子女: '子女身体虚弱，美丽而得人缘。',
    周转: '不宜大，小调尚可。',
    买卖: '有贵人，速决有利。',
    疾病: '为假象之病症。概与心病、心理、情绪有关系。',
    等人: '会来。但远方者不来。',
    寻人: '已在东北或南方亲友家，可寻。',
    失物: '东北或南方，可寻。',
    旅行: '可。宜近不宜远。',
    讼词纠纷: '有贵人和解，宜速解决，拖延不利。',
    求事求职: '条件勿太苛求，可成。',
    考试: '不理想。但人家以为你读得不错。',
    改行: '改行宜，但勿夸大或太过。开业者吉利之象。',
  },
  剥: {
    主要: '表剥落之意。主大凶象。地动山崩，一时群山崩落为平地，表示很严重的动荡、变化，而致使重大的伤害、不幸的产生。最易有急症、意外的血光之灾。事业、感情、婚姻，得此卦需有心理准备，所有之前的努力可能要重新再来了。唯此卦利於重新到外地发展，或另寻对象也可。',
    特性: '反应灵敏，思想新颖，走在时代尖端，有研发长才，一生多贵人运。有意料之外的机会和柳暗花明之遭遇。',
    解释: '剥削，剥落。',
    运势: '运带破、带衰，有不利之灾祸，不可抱持野心，自作聪明，否则自掘坟坑。得此卦者，必有与欢场女子耗费饯财而又被陷害之苦恼，且有受部下连累而损财，为不得志之时运也。',
    爱情: '卦象阴太盛，故男求女利，女求男不利。口舌多，不可奢望太高。',
    家运: '正处於没落之时，辛苦劳心，有心挽回者可得安，否则有别离之险象。',
    胎孕: '虚惊之凶象。',
    子女: '缘薄，不和，体弱。',
    周转: '无望。',
    买卖: '难成。若成亦有损。',
    疾病: '为胃胆之病。男人防性病。',
    等人: '不会来。',
    寻人: '在东北方的山上或水边寻之。',
    失物: '已失於水中。',
    旅行: '不宜。有险象。',
    讼词纠纷: '因财禄争斗致讼。',
    求事求职: '顺而止，要慎机行事，不可粗心大意。',
    考试: '落榜。',
    改行: '改行不利，必损财。开业者有阻碍，切勿意气用事，否则有害。宜再待时。',
  },
  复: {
    主要: '表重复再来、周而复始之意。主是个状况卦，吉凶未定。好事会重复，但坏事也会重复再来的。此卦显现一种循环的状态，可能是好、也可能是坏。',
    特性: '为最佳辅佐人才，做事谨慎，调理分明，精企画，组织，一生财运丰足，乐於助人，排解别人困难。人缘佳。',
    解释: '一年复始、万象更新。',
    运势: '一阳来复，万事苏伸，不久就会好转。初时不宜急进，脚踏实地则有一本万利、开运亨通之象也。',
    爱情: '性急则败，缓可得利。',
    家运: '昌隆茂盛，渐曙光明之象。',
    胎孕: '主产母有惊惧之灾。',
    子女: '辛劳之後必得享福，儿女将来出人头地也。',
    周转: '难求，反覆终可入手。',
    买卖: '有是非，故勿太急，可有利。',
    疾病: '为慢性病、会反覆发作，病症主在肠胃或神经系统之症，注意肝功能。',
    等人: '会迟到。',
    寻人: '自身反覆终人不动，但七日内可回。',
    旅行: '有利。',
    讼词纠纷: '无罪，但须数次方可了解。',
    求事求职: '勿太急，可成。',
    考试: '愈考愈好。',
    改行: '改行鼓励。开业者鼓励，但宜沉着，勿急躁。',
  },
  无妄: {
    主要: '表不要有一些空幻的想法，或是你的想法可能有点虚妄不切实际。主小凶。建议凡事要务实、踏实一点。也表示目前所处的状况，是有一厢情愿的心态。或是有点「妄想症」的不正常心理。',
    特性: '言词犀利，易生是非，愤世嫉俗，不流俗，求知慾强，重精神，曲高和寡。',
    解释: '无妄之灾。',
    运势: '无妄的道理者，乃真实无虚要踏实。故若做事操守而能坚贞固执者吉。若不行正道，行为不检点者，必然有灾祸至也。切忌醉沉於利、慾之中，慎之。',
    爱情: '成功与否，须看各人之修为德性而定。然此卦有不顺遂、不和，或有被伤害者欲分离之象耳。',
    家运: '有不和之情况，因另一方各受外界诱惑而心乱，须谨慎，否则导致破运。',
    胎孕: '或有惊终无碍。其性刚强，切勿过度溺爱，否则不受教也。',
    子女: '注意与其父不和而离家出走。',
    周转: '诚心有望，不诚免谈。',
    买卖: '若不要过份苛求，有利。',
    疾病: '虽有虚惊，尽力疗养可脱险境。为呼吸器官及神经性之疾病，若意气用事而不仔细调理则凶也。',
    等人: '一定会来。但有不欢而散之象，宜以和为贵。',
    寻人: '已定远，不易寻找，东北或西北之向。',
    失物: '海底捞针。',
    旅行: '为公事出差则吉。若私人远行，但有所交易者，则勿远行为宜。',
    讼词纠纷: '有贵人和事而脱散无虞也。',
    求事求职: '毫无头绪，勿过於草率为宜。',
    考试: '笔试有利，口试不利。',
    改行: '改行者不宜勉强，开业者可行。',
  },
  大畜: {
    主要: '表会有很多的积蓄、不动产的来到。吉相之卦。可以考虑较大的投资案，只是现金周转可能会较不利。蓄也有守财小气之象。',
    特性: '反应快，追求新知，不断创新，研究新事物，待人谦虚，修养好。贵人多助，容易少年早答，年轻就在社会露头角。',
    解释: '大有积蓄。',
    运势: '大凡诸事不可好高鹜远，脚踏实地、务实行事，始能成就大业。以蓄德为主可吉，若因得势而气盛凌人，目空一切者，终招失败之象。',
    爱情: '虽有些阻碍，但终有望，然气盛则自招叛而破。',
    家运: '须知蓄者以备急需也。明此道理，持之力行，则可亨通。',
    胎孕: '占得此卦主生贵子。',
    子女: '儿女必声扬家风，可得幸福之象。',
    周转: '可成。',
    买卖: '再谈可成交，且有获大利之象也。',
    疾病: '为脑、腹部脾、胆之疾，可能结石发炎之象。或头部外伤。但可冶。',
    等人: '不会来。',
    寻人: '途中虚险，若一人等待必有凶。若是出走者，不必寻，会自回。',
    失物: '隔些时日可寻，东北水边。',
    旅行: '吉利。',
    讼词纠纷: '因土地之事，有头无尾。有破财之象，但纠纷可化解。',
    求事求职: '不利。再待时。',
    考试: '上榜。',
    改行: '改行不宜。开业者要有不怕挫折之决心全力以赴，有利可得。',
  },
  颐: {
    主要: '表养也。主小吉带一点凶。养跟饮食有关，也表示养育、教育的意思，对情势需要再充实、了解，不要贸然的就下任何的决定。颐，又与口有关，故需慎防小人口舌之灾。',
    特性: '先苦候乐，失败後成功型，物质金钱运佳，乐善好施，贵人多助。不断提升修养自己，容易得到社会好的名声。',
    解释: '静卧，不向前冲。',
    运势: '有欠缺考虑、鲁莽妄动之害。若有怀阴谋，与人互相猜疑或争端者更不利。要守正道而善行之，凡事切忌任性非为。',
    爱情: '不可傲气凌人。虽有些阻碍，但此情令人意乱情迷，耍冷静观察。因为颐卦为养，即是食，对自己本身之损益，跟自己吃下去的东西，是相对性质的反应。故宜重新估计对方的价值，是益是损？切勿因情误了自己的青春。',
    家运: '陷於痛苦深渊，有身败名裂之象，宜改进言行，冷静反省，力求幸福才是。',
    胎孕: '宜求香火保佑。',
    子女: '过份溺爱反有害。尤其须注意儿女健康，以免日後因身体虚弱而不能获福。',
    周转: '是否成功，要看自己有否诚心。',
    买卖: '守旧为宜，不宜新事所为，否则不利。',
    疾病: '为胃肠、脾胆发炎。占久病凶兆，久病者险。',
    等人: '难来，中途有碍。',
    寻人: '不久相逢。东北或东之向。',
    失物: '在屋内杂物中寻。',
    旅行: '可。但注意饮食与语言得失。',
    讼词纠纷: '有头无尾，我告他人终久不成，若有些利，速予和解。',
    求事求职: '不成。',
    考试: '尚可。',
    改行: '改行不宜，勿勉强。开业者不利，再待时。',
  },
  大过: {
    主要: '表犯了一个很大的过错哦！主大凶。诸事不顺，切忌此时作任何的决策。需努力找出问题之所在，尽快解决才能扭转劣势。',
    特性: '心直口快，性急，易得罪他人，是非小人多，但交际层面广，朋友多，但也易受朋友之拖累。',
    解释: '极大过失。',
    运势: '诸事衰退，多烦恼，恐水难或犯官符。有力不从心，负担过重之象。逢挫折而将坠落之时运，故得此卦者，凡事切忌经举妄动，否则有过。',
    爱情: '双方眼光均太高，且各已有相好之人，花心又不实，何需再另谈感情之事？无望。',
    家运: '困难重重，摇摇欲坠之衰运。夫妻感情不睦，且均有向外发展之趋势，若想挽回此破象，双方宜力图改进自己的缺点，否则破裂也。',
    胎孕: '女儿或男儿均与六亲缘薄，育养困难。',
    子女: '子女与双亲间感情薄弱，又缺乏谅解，有症结耳。',
    周转: '无啥希望。',
    买卖: '不可做超过自己本身财力之生意交易，否则定有折损。亦不成。',
    疾病: '有更严重之趋向，为肺部、神经部份的疾病。女占得此卦，有妇女经滞、妇女病、不孕、肿瘤之疾，须耐心治疗，疾可治。',
    等人: '不会来。',
    寻人: '不易找到，可向东南方或西方。难寻。但若动用人手共寻找，可见。',
    失物: '小失可追回，大失无望。若是东南向或西向则较有望。',
    旅行: '不去也罢，不利。',
    讼词纠纷:
      '乃熟人、亲近之人之内患。有文书上的麻烦，求和亦寻无心计。官符也。',
    求事求职: '困难很多。',
    考试: '苦恼也。不佳。',
    改行: '改行不宜图不相应之事。开业尚属虚，且待时候。',
  },
  坎: {
    主要: '表陷溺被水淹灭之意。主凶象。四大难卦第二卦。困难已到来了，此时正在挣扎中，随时会被淹灭而破散。若能破釜沈舟、奋力一击，或许尚有转机，需积极找求贵人，才会有生机。',
    特性: '不安定感，好冒险，投机，变换工作环境，爱情多困扰不顺。宜从事旅游服务社交艺术创作业。',
    解释: '漂浮，被情所困。',
    运势: '危机重重，宜容忍自重，保持心境开朗，沉着应付，则可有脱险之机。吃得苦中苦，方为人上人，凡事莫与人争长短，否则有不幸灾害。',
    爱情: '溺於情爱之中，然本身和外围的困扰和压力都很大。',
    家运: '时运不济，陷於困境之中，不和，且有分裂之忧，凡事宜慎重行事。',
    胎孕: '临产有虚惊，难产之象。',
    子女: '子女多劳苦，宜忍辱负重，会有出人头地的一大。子女宜防水厄之灾。',
    周转: '难成。',
    买卖: '亏损。',
    疾病: '肾脏、生殖器官等部位之疾。久病者凶，近病者小凶可治，速医或可救。',
    等人: '不来。',
    寻人: '此人因家庭不和或不得志而出走，速寻北方水边。',
    失物: '难寻。或被盗。',
    旅行: '不宜；尤忌游泳、近水边，有被水淹灭之势。',
    讼词纠纷: '有理亦不可因有理仗势。此卦不宜讼诉，有险。大不利之象。',
    求事求职: '不利。',
    考试: '不理想。',
    改行: '改行不宜，应暂时守住本行。开业者不利，有陷入困难之象。',
  },
  离: {
    主要: '表智慧、明亮、温暖。六冲卦、纯卦，主大吉大凶、大好大坏之象。也表，虚象不实之意。',
    特性: '爱现，喜欢与人竞争，冲动，自尊心强，不服输，容易遭人排斥反对。依赖自己努力，他人助力较少。',
    解释: '变动突发火光。',
    运势: '虽然目前外观极盛一切顺利，可能为假象、虚象，而且双火之燃，其内部、内情已有所损，又双火之烈，如烈日中天，故凡事宜守之，勿太急进，待人以谦和、理性，否则必有所损失。宜顺从长辈，勿因急躁而妄动或意气用事，则可欣欣向荣也。',
    爱情: '对方明亮有活力，性急者弄巧成拙，诚静者其情必有成就。',
    家运: '富有幸福之运，必须保持仁和谦恭，骄傲者，自大招致失败，宜谦逊处世。',
    胎孕: '胎有不安。产母不宜出外游。',
    子女: '子女幸福，但有娇生惯养之嫌，勿疏於教导为上',
    周转: '可得到上辈的帮助渡过难关。',
    买卖: '交易必得利，忌用不法手段。',
    疾病: '心脏及眼症，虽重，耐心调养可无碍。虚火很旺，情绪浮动，需特别注意血光意外之灾。卜急病有解，慢性久病卜得此卦则凶。',
    等人: '一定会来。',
    寻人: '此人因受到诱惑或煽动而出走。南方可寻，且小有是非。',
    失物: '於南方，附在某物件上。急寻可得。',
    旅行: '可。但勿太急。',
    讼词纠纷: '内动则外凶。他人理亏，自取其危，我侵他人却又成凶。',
    求事求职: '尊重长辈安排。待时机可获良好事业。',
    考试: '成绩不错。',
    改行: '改行可极力推展。开业者吉象。',
  },
  咸: {
    主要: '表感动也。主吉象。感，有如青春男女，清纯无暇的感情，心心相印有感而发的情感，得此卦，与感情有非常大的关系。也可引伸为对一种理念的认同和欣赏。咸，并无利慾的情色纠葛，是属於比较「感性的」一种感觉。对男女之间的感情，是最大的吉象。',
    特性: '罗曼蒂克型，异性缘佳，感情丰富，六亲缘浓，口才佳，贵人多助。',
    解释: '合和。',
    运势: '吉祥如意。但勿为不正当的感情而意乱迷惑，或过於冲动。然得此卦时，会有男贪女爱之筝，非名正者必遭损，宜慎之。',
    爱情: '互敬互爱，清清白白者定成眷偶。若名不顺而贪爱者，当知悬崖勒马。',
    家运: '目前非常融洽，尔後亦然。如夫妇间另有不正当之交往者，宜明哲保家，避免有越轨情事为宜。',
    胎孕: '有兴家和泰之运。',
    子女: '感情极融治，幸福。',
    周转: '可成。',
    买卖: '如期望，交易可成有利。',
    疾病: '泌尿系统之症或胸部呼吸系统之症。须疗养得法。',
    等人: '会带来好消息。',
    寻人: '因色情之事出走，不久会出现。其人有在住处动身不得之忧，恐有些是非。',
    失物: '东北或西南方内寻找，可得。',
    旅行: '吉利。',
    讼词纠纷: '恐为美色纠纷。诸事是非宜和解，有贵人可解之。',
    求事求职: '皆能如意得偿。',
    考试: '成绩佳。',
    改行: '改行吉利，可进行。开业大吉大利。',
  },
  恒: {
    主要: '表维持不变的迹象。主是个状况卦，吉凶未定。显示一种会持续很长久的一种状况。好的或坏的情形，都将会再持续下去。事业投资、感情婚姻都不宜在此时做任何的计画或变动。',
    特性: '主动，积极，坚忍奋斗，百折不挠，律己严，反应快，追求成功，理财佳，组织能力好。',
    解释: '要有恒心。',
    运势: '诸事亨通而没有犯错方能恒久。其象利於正常固守，并须有毅力持行。雷风者，知守常则吉，妄动者则不能亨通。',
    爱情: '顺利如意，有美好结果。',
    家运: '全家上下均各循正轨而行，幸福家庭也。',
    胎孕: '胎有烦恼，但无碍。',
    子女: '与双亲感情融治，幸福之至。',
    周转: '不成问题。',
    买卖: '有贵人，得力终成。利益双收。',
    疾病: '慢性病及宿疾。胆、肝之症，须长期治疗不易在短期内治癒。',
    等人: '迟到。',
    寻人: '外出之人平安无事，会自己回来。',
    失物: '室内可寻东南方，室外宜向西北方。可寻。',
    旅行: '吉利。',
    讼词纠纷: '因小人口舌而起，有惊无害。宜速谋和解，拖延不利，反凶。',
    求事求职: '向东南有利。',
    考试: '高分纪录。',
    改行: '改行不可任意图谋。开业者吉利可行。',
  },
  遯: {
    主要: '表退守、退步之意。主凶象。一切事项均需停止下来，因其中可能有小人、或障碍、阴谋在破坏中。退另有表示退步之意，功课、事业，均不再如同往常般的成绩了！',
    特性: '外华内虚，内心不易满足空虚，自我要求高，不善人际关系，但有贵人运。宜追求心灵宗教寄托。',
    解释: '隐居退守。',
    运势: '小人道长，君子须远离小人，诸事宜守。艮者，止也，言行物事要谨慎，才能得安而运泰。若遇小人之害，见陷於是非之地，勿管闲事，识时务为俊杰。',
    爱情: '落花有意，流水无情，适时地另外选择其他对象吧！',
    家运: '须多积德行善，可得安祥。若常争端则衰运，且有别离之象、离婚之象。若曾数经波折之君子，历尽沧桑，则有先恶後吉之运。',
    胎孕: '母子不安。慎之。',
    子女: '儿女体质虚弱，有亲子不和之象。',
    周转: '不成，有碍。',
    买卖: '宜守，不能大事进取，否则失败。',
    疾病: '所患者为经络血行不良，袖经系统之麻痹、疼痛，或手骨、头疼之症，常病也。',
    等人: '遁，藏也。不能来。',
    寻人: '下落不明，为家庭之事而离乡背井，寻亦难觅。',
    失物: '已落人他人手中，难寻得。',
    旅行: '不利。莫去为安。尤其山路有险。',
    讼词纠纷: '有一方因逃移而去，是非真假无须论断，争之不利，宜罢手。',
    求事求职: '须待时机。',
    考试: '不理想。',
    改行: '改行不得时，开业不利。',
  },
  大壮: {
    主要: '表天上打雷、声势浩大，行动迅速、积极之象。此卦一般以吉处藏凶来论。因为对人、对事最忌冲动。此卦有过於不理性冲动之象，容易犯错。尤其有血光之祸，被人殴打、伤害之意。得此卦，凡事再三思为吉。',
    特性: '爱冒险，领导慾强，喜怒易形於色，不喜欢太平淡安定的工作生活。金钱支出大方，朋友人缘不错。',
    解释: '声势浩荡。',
    运势: '刚强过盛者，若不思正与顺，则有躁动之害耳。虽运势强大，时至当盛之期，但忌血气方刚。处事欠思虑而遭破运，宜力持和平、顺气，勿得罪人，否则会因此而招来失败，切勿贪妄。',
    爱情: '有成就，但缺乏仁和谦恭，易遭反悔，须慎之。',
    家运: '已壮大且富有，但亦有似成而空之感。',
    胎孕: '子母有难非喜。',
    子女: '儿女有自视太高，目中无人之情。',
    周转: '勿再三借贷，如此反而不利。',
    买卖: '可成功与获大利，但往後要小心，有反遭不利之情况。',
    疾病: '所患为急性肺炎、脑病等。尤其平常健壮而临时起病者不利。',
    等人: '迟来。',
    寻人: '为斗气而出走，若寻找途中不见，则难查下落，东方或西北方。',
    失物: '大多已失。',
    旅行: '有无谓灾害，不宜。',
    讼词纠纷: '和解为宜。勿斗气。',
    求事求职: '可顺利。勿太刚强行事。',
    考试: '有好成绩，但勿太骄傲。',
    改行: '改行可，不过勿太勉强。开业吉利。',
  },
  晋: {
    主要: '表进取也。主吉象。火为太阳，所以是「日出於大地、光明乍现」，阳光准备要照耀大地，一片欣欣向荣之象。事业投资、升迁、婚姻、感情均是有雨过天晴，奋发向上的吉象。',
    特性: '荣誉心重，择善固执，爱面子，外华内虚，喜领导他人，宜往社交，服务业，代议士方面发挥。',
    解释: '很明白，很明亮。',
    运势: '事业繁荣，声誉渐高，於人、事、物均获宏利之际，与人共事者吉。但要知进德虚怀，即是诸凡对人、事宜谨守德操，不可傲溢心怀，否则有破象也。但财运旺。',
    爱情: '虽如日中天，切不可过於骗纵、蛮横，若为人不行正道，虽然光明就在眼前，且兴隆幸福，又难免夫妇失和，家运不振耳。',
    家运: '家庭内部不和，有口舌争端之害，并有散离之象，力持仁与和，运破败，离散之凶象也。',
    胎孕: '平。',
    子女: '多才智，聪明而贤孝。',
    周转: '可获得强大资金。',
    买卖: '进取得利，并获得巨财。',
    疾病: '为胃肠之疾，长病者凶，近病者无碍。',
    等人: '女者会来，男者不一定会来。',
    寻人: '见於西南方或南方。',
    失物: '容易找到。',
    旅行: '吉利。',
    讼词纠纷: '迟疑终得理，能圆满解决。',
    求事求职: '受人提拔，可有成就。',
    考试: '科甲登榜。',
  },
  明夷: {
    主要: '表火入坑中，阳气被阴气所伤害，被小人所重伤。主大凶象。诸事不宜，运最背之时。',
    特性: '朋友多，心慈，重友谊，喜追求高层次的境界，理智和感情常矛盾。酉时会牺牲一切，去完成自己的理想。',
    解释: '浑沌不明。',
    运势: '逆势下降，时运未济，物事劳苦，逢小人加害，光明受到掩蔽而失光明，所以多艰难之运也。有万事阻滞，遇事迷惑、受灾。故宜守贞固之道，忍耐自重，等待时机。',
    爱情: '有被背叛抛弃之象，未能被对方接受，不成也，而且受伤很深重。',
    家运: '衰弱、多劳，家人受累，恐有诈欺、官符。须知邪恶之人定有果报，凡事心地坦荡可渡难关也。',
    胎孕: '产母有惊。',
    子女: '受子女牵连，陷於痛苦深渊之中。',
    周转: '不成。',
    买卖: '难成，用谋方就。',
    疾病: '为难治之病难。为腹部之疾，或心脏。慢性病卜之，病有不安、严重、凶险之象。',
    等人: '不来。',
    寻人: '西南或南方，虽在附近但难寻。',
    失物: '在身上或衣服可寻得。若不在身上衣服里，则是被某物掩盖难寻。',
    旅行: '不宜。',
    讼词纠纷: '见官必难逃走，其他纠纷争吵有失，和解为宜。',
    求事求职: '日下无希望，须再待时，需防被骗、失财、失身。',
    考试: '无望。',
    改行: '改行不利。开业不宜，亦不成事。',
  },
  家人: {
    主要: '表同为一家人。主小吉之象。事事以家人为重之意，如事业投资均需一家人合作共事为佳。感情，为成家之吉象。',
    特性: '为人热忱，待人谦恭有礼貌，善交际，外表积极内心保守。喜家庭生活，爱小孩小动物。',
    解释: '家人离散，吉中带凶。',
    运势: '平安无事，且有喜事之象，与家人共事者大利。',
    爱情: '有情人终成眷属。',
    家运: '物事畅达如意，和乐之象。',
    胎孕: '不碍。',
    子女: '儿女孝顺。',
    周转: '难调，但可成，宜找自家亲人为佳。谨守诚信可无阻。',
    买卖: '有超值的利益可得。交易可成。',
    疾病: '腹部小病不严重。若为心脏病则危重。',
    等人: '自来。且有吉事相告。',
    寻人: '因赌气出走，不久便见。',
    失物: '南方或东南，有失，未得见。遗失於室内易得。',
    旅行: '大利。',
    讼词纠纷: '得理，虽有忧疑，但无妨。能有合理的处理方法。',
    求事求职: '有良好机会，勿失。',
    考试: '上榜有名。',
    改行: '改行勿急。开业者可照计划而行。吉利也。',
  },
  睽: {
    主要: '表乖危、叛逆、背离之象。主凶象。意见不合，彼此争斗、任性、不协调。事事均不顺，且有被出卖的危险，注意犯小人。',
    特性: '经常事与愿违，个性敏锐，理想化，感情与理智容易矛盾冲突，易生孤独感，宜往专技艺术发展。',
    解释: '防小人。',
    运势: '水火不相容，则气运不通，势行低落，诸事难成。凡事有违叛之时，若能处变不惊，不逞强出头，尚可转危为安。有家内不和，亲友疏散之情。宜力持温和，忍耐去克服困难，始可渡危。',
    爱情: '彼此间无意向，无望也。',
    家运: '困苦离亲之象，不和睦之情。家运衰颓，虽可得小利，但也济不敷出。',
    胎孕: '无碍。',
    子女: '子女不和，有骨肉无情之不幸，且其子女六亲缘薄无靠。',
    周转: '难以如愿。',
    买卖: '多阻碍，难成，若成者亦必损。',
    疾病: '有诊断错误，治疗上有失先机之危，为心脏、血液、或胸部机能之病症、心脏疾病。病者冷热不和，重症危，经症速转医可治。',
    等人: '不会来。',
    寻人: '因口角负气出走，其志不同故不易寻。',
    失物: '已被人夺去，追不回了。',
    旅行: '不宜，有灾。',
    讼词纠纷: '宜速谋和解，不然反成无理。',
    求事求职: '无希望。',
    考试: '落榜。',
  },
  蹇: {
    主要: '表寒足之意。主凶象，四大难卦第三卦。冰天雪地中赤足而行，表示现在处境多麽的艰辛困苦，却又不能放守不管，只能硬撑到底。此时虽是无奈，但也总有苦尽甘来的时刻。人事均很为难之时，事业、感情均有骑虎难下的困境。',
    特性: '幻想多，重情调，喜新厌旧，有设计研发才能，追求理想化的生活，不切实际，故内心易有挫折感。',
    解释: '跛足，执行困难。',
    运势: '衰运，多灾难，进退两难之处境，要自重而不可妄动，此时应守正道待时也。凡事杂乱，更防小人之害，绝不可涉险境，否则灾害必至。',
    爱情: '困境重重，情缘有破，舍弃难定之际。然此卦主破象。',
    家运: '六亲无情，有贫困、不幸之象，逆差者，其险难脱，善者宜致力克服挽救。宜注意家人有重病、血光之厄运。',
    胎孕: '胎不安，孕有灾。',
    子女: '子女手足情薄，六亲少靠，劳碌之命也。',
    周转: '难调，多不成也。',
    买卖: '失利。交易不成。',
    疾病: '肝脏、胃、脾胆之症，或有肾、受伤害之疾。',
    等人: '不会来。',
    寻人: '速报警帮寻，得贵人则可回。',
    失物: '可寻东北水边或沟内。',
    旅行: '不宜。有险象。',
    讼词纠纷:
      '不宜见官，只宜逃避，因有严重的暗斗而不得停罢，终必有险，带血光之厄。',
    求事求职: '眼前毫无希望。',
    考试: '落榜。',
    改行: '改行不宜。借贷强行并非良策。开业者不宜。有是非、损耗之象。',
  },
  解: {
    主要: '表解决之象。主凶带小吉之象。冬雪春化之意。冰冻三尺非一日之寒，事出有因、但已是到该解决的尽头了！只是化解之日还很长，要抓住重点方向，好好努力坚持下去，还是有可能再成功的。',
    特性: '耐性差，爱变化，喜热闹场合，多学不精，思想兴趣不稳定，易受外界影响。一生多位他人排解事务，自己却少有他人之助。',
    解释: '解冻。',
    运势: '顺势而行，速把握良机、坚持努力，快速处理可成。贵人在远方，有助，宜出外营谋。大运在西南。',
    爱情: '不用犹豫，坚持可成。',
    家运: '初有困难，但难关已过。开花结实之兆。',
    胎孕: '孕安。',
    子女: '初时因子女牵累而劳苦，但其子女越长运越好之兆，故苦後得甘，已可得到幸福。',
    周转: '速进行可成。延迟不利。',
    买卖: '即速交易，可名利双收。',
    疾病: '为肠胃、神经系统之疾。',
    等人: '一定来。',
    寻人: '北方寻之可见。',
    失物: '遗失不得全出。',
    旅行: '吉利。但莫乘船，其他可以。',
    讼词纠纷: '可有利解决。',
    求事求职: '有贵人相助。',
    考试: '题榜在望小成。',
    改行: '改行速改有利。开业者有很大的展望。',
  },
  损: {
    主要: '表小有损失也。主凶中带吉之象。「赛翁失马、焉之非福！」是此卦最好的解释。投资、事业、借贷、感情皆是失意不顺之时，然对事要有信心，学得经验将可得到更好的结果。若将要投资则不可。',
    特性: '心地善良，富同情心，悲天悯人，体贴他人，善於照顾，安慰别人。牺牲小我，完成大我精神，生活节俭，大方助人。',
    解释: '有所损害、损失。',
    运势: '诸事不如意，若倾於利慾不当之心则有灾，散财之损。必须能够悟其所损，方能挽回局势。与人共事商量则可收损失之象。',
    爱情: '以诚信可以成功，可得良缘，但此卦女者卜得有较大的损失。',
    家运: '正处於受损之时，审慎挽救颓势他。',
    胎孕: '产母有凶象。',
    子女: '儿女多诚实孝顺，幸福之格。',
    周转: '可达目的。',
    买卖: '虽有损，但终得利。',
    疾病: '为身心衰弱、脾、胆硬化结石，消化不良，或贫血，治疗後无碍。',
    等人: '可能会迟到。',
    寻人: '在东北或西方友人家中，可寻。否则会自回。',
    失物: '北方可寻，别处已失难寻。',
    旅行: '迁居可，旅行不宜，有难。',
    讼词纠纷: '争斗之事，他人先有损，破些财，勿再争端，宜解。',
    求事求职: '可慢慢受重用。',
    考试: '多努力，榜上可题名。',
    改行: '改行可行，勿急躁，宜周密。开业者吉利，宜有耐力。',
  },
  益: {
    主要: '表利益、好处。是由上对下的一种好的给于。主吉象。对事业投资都有相当的帮助，可得贵人来相助、扶持。',
    特性: '重大我，轻小我，热心公益，爱热闹场合，团体事务协调工作。对自己生活的要求少，在朋友口碑中不错。',
    解释: '有利益，有好处。',
    运势: '乘盛吉之运，可得他人之助而诸事顺畅，家业有成。大致吉利之运也。',
    爱情: '相成相益，可成良缘。',
    家运: '有喜悦之象，发达之意。',
    胎孕: '胎有不安。',
    子女: '得家人之喜爱，天真顽皮。',
    周转: '可成。',
    买卖: '可得大利。',
    疾病: '足或胃部疾病，可癒。有小血光应无碍。',
    等人: '迟来。因有好事相告。',
    寻人: '难逢，防有害。若出走者会自回。',
    失物: '遗失之物有变动，深藏於东南方。',
    旅行: '可行，宜注意小有不测、变动之碍。',
    讼词纠纷: '双方可和。若因女人田地粮米之事，恐有牢狱之灾。宜自修省。',
    求事求职: '大好时机，勿错过。',
    考试: '成绩优良，父母有喜悦之象。',
    改行: '改行有利。开业者则大有利图。',
  },
  夬: {
    主要: '表决定或缺失。是个状况卦，吉凶未定。所迟疑的事情，一定要有个决定不能再拖延，至於如何决定，可视变卦而定。否则在有所拖延，就成凶象了！',
    特性: '思想敏锐，有理想抱负，果决，务实主义，实事求是，不投机冒险，有孤独感，宜从事专技工作。',
    解释: '卦相奇特角龙昇天行大运。',
    运势: '日前虽然鸿运当头，终会遇到困难与危险，凡事不可轻举妄动，宜随时警惕自己，留心意外灾害。住所可能会有变动，亦有文书、契约之失利，易生错误。慎勿傲气或自以为是，不谦和者定有灾。',
    爱情: '男人不专情之象，乃孽缘也。',
    家运: '夕阳虽好，却近黄昏，往後有陷於惊动、痛苦之境。因此卦有盛极必反之象。',
    胎孕: '无灾。母胎宜节食物，且勿发怒气，否则有难产之虑。',
    子女: '有先吉後凶之象。',
    周转: '不可急踩，须诚意，光明之态度可成，否则有不利之象。',
    买卖: '须诚信交易，有失败破财之害。',
    疾病: '宜速治疗。所患是胃部、头部之病症。',
    等人: '不必等了，不会来。',
    寻人: '宜速寻找，延误不利。',
    失物: '遗失大致可知，但难得之意，西北方。',
    旅行: '不利，途中有难。',
    讼词纠纷: '不利与人私下争执，宜寻法律途径解决。',
    求事求职: '有困难，或得不到好职位。',
    考试: '不理想。',
    改行: '改行者要慎重考虑。开业者不利，如已备妥 小心难关重重。',
  },
  姤: {
    主要: '表邂逅、不其而遇，非预想中的事情来发生。主是个状况卦，吉凶未定。逅，会带来意外之喜、也会带来意外之灾，需视动爻变卦吉凶来论断。而「姤」意，卜感情通常是有其他的感情发生的迹象、与情色非常有关系。',
    特性: '杞人忧天，虎头蛇尾，易感伤，有艺术美学之天分，较理想化。',
    解释: '二女争一男。',
    运势: '阴长阳衰，男人倾防因色受祸，诸事不如意，不能上进，沉於女色之凶象也。凡事进取宜慎重。',
    爱情: '男女对象均不理想，有第三者出现，因而徒劳无功，会离散。',
    家运: '家运渐衰，不祥之兆。有女人口舌，亦有感情之因，损财之运。',
    胎孕: '孕无碍。然须注意子女均有品性不良之趋向。',
    子女: '子女多有志行不坚定，给父母亲增加麻烦，不幸之象。难管教也。',
    周转: '有意外阻挠，难调，另谋他法为宜。',
    买卖: '不能如意，受奸诈之害而导致失败也。',
    疾病: '常为外来感染或外伤、外来冲煞等症。',
    等人: '女方会来，男方不一定会来，因为途中遇到别的女人之故。',
    寻人: '会在意想不到的地方相遇。寻人不易，西北之力。',
    失物: '遗失之物与女人有关，或许无意之中可以找到。',
    旅行: '可。但切记勿近女色，以为艳遇其实有灾。',
    讼词纠纷: '女人在内，口舌是非播弄之象。多数为女色方面之纠纷。',
    求事求职: '难成。',
    考试: '不理想。',
    改行: '改行不利，开业不宜，强行必受小人之害。',
  },
  萃: {
    主要: '表精华聚集之意。主吉中带小凶之状况卦。有经过挑选、或是在因缘际会下，而重新再一起共事的意义。事业公司，可能人事有需要再重新安排挑选过。',
    特性: '温文富同情心，慈悲心，被动，略消极，乏耐性，博学不专。人缘佳，易有情爱困扰。',
    解释: '人才聚集。',
    运势: '昌隆，得信於人，承上辈照顾，事业吉昌，但宜小心财务上的纠纷。',
    爱情: '可达成其愿望。',
    家运: '家庭融洽如意。防财务歧见之意外。',
    胎孕: '胎安。然产母病多，注意保养身体。',
    子女: '手足相敬如宾。',
    周转: '可得到上辈支持。',
    买卖: '可获利。',
    疾病: '所患是胸部与腹部之疾。治疗得宜可平安。',
    等人: '一定会来，且有佳音相告。',
    寻人: '会自己回来，不用担心。',
    失物: '遗失之物在西南方，迟至无用，速则可寻。',
    旅行: '吉利，并有所获。',
    讼词纠纷: '争端不利，和解为宜。',
    求事求职: '可顺利，有成就。',
    考试: '常保佳绩。',
    改行: '改行吉利。开业适时，可按计划进行。',
  },
  升: {
    主要: '表一种情势有如乘风而起，会越来越旺。主是个状况卦，吉凶未定。有点偏小吉。尤其是在气运的转变中，将会显现得很明显。此时可以做一些计画，但还尚不可执行。',
    特性: '有计画安排自己的人生，做事考虑周详，待人谦和，追求新的知识。喜欢多彩人生，重生活品味。',
    解释: '逐渐高升。',
    运势: '诸事向上发展，开运之象，南方有吉庆，可名利双收也。',
    爱情: '不宜过急，慢慢追求可成。',
    家运: '渐进而升至繁荣，积小以成大之吉运。',
    胎孕: '不宜修造动土，修造必犯产母。',
    子女: '子女渐有成功之象，可贺。',
    周转: '勿太过急，可成。',
    买卖: '多有利，应於亥卯日。',
    疾病: '腹部、胆或下部疾病。',
    等人: '迟到。',
    寻人: '动身则见，但恐自身有阻，与他人同去可，宜向北方。会自己回来。东南。',
    失物: '可寻，但迟。',
    旅行: '吉。远行有利。',
    讼词纠纷: '宜进不宜退，坚持可成，缓则无大吉也。',
    求事求职: '有利。',
    考试: '进步。进取有利。',
    改行: '改行吉利。开业者吉利，渐渐获利。',
  },
  困: {
    主要: '表很大的困难被困住了。主大凶象。四大难卦第四卦。四处无援，最困难之时。事事恨难再有进展，只好静待时机，是此时最好的选择。',
    特性: '不满足感，不喜平淡生活，生活过於理想化，爱变化。自立自强，辛勤工作，善於用脑工作，不适领导工作。',
    解释: '被困住。',
    运势: '不如意，被小人欺，劳而无功，破损之灾。一事难成，运衰也。宜守己待时。',
    爱情: '失败之象，难成气候。',
    家运: '家庭之主有屈於下风，被内助压迫者，亦常生反弹，吵架滋生。为黑暗时期，宜忍辱负重，期待黎明到来。若不谨守正道者，有失和、破兆也。',
    胎孕: '胎安。将来劳碌命格。',
    子女: '劳苦之命，但行为端正者，终可得福也。',
    周转: '求人不如求己，凡事需量入为出。若为女色破财，当然求助无门。',
    买卖: '不能如愿，有挫折。',
    疾病: '为下腹部之病。口、股之外伤，其病可治。',
    等人: '受到阻碍，不来或迟到。',
    寻人: '途中可遇．来者自来也。',
    失物: '遗失之物不出其门，或存箱笼之中，但难找。',
    旅行: '不利。不宜远行。',
    讼词纠纷: '防牢狱枷锁之灾。故凡事宜得过且过，太计较不利也。',
    求事求职: '不得时亦不得意，再待时机。',
    考试: '不理想。',
    改行: '改行不宜。开业者须再待时。',
  },
  井: {
    主要: '表小水之源，有格局小却能过活之心态。主小吉象。井水虽不能种植、畜养，却能养人度日，显示心力之有限，不能做太大的计画。也另表示视野没有很宽广，比较短视，保守心态重。',
    特性: '积极，主动，创新，改革，反应快，口才佳，组织能力强，有领导才能，贵人多助，社会名誉佳。',
    解释: '井底之蛙。',
    运势: '缺乏衡力，因井为静而不能移之物。故凡事已无法进取，不如守之泰然。',
    爱情: '任其自然发展，但情况不怎麽好，有破裂之相。',
    家运: '诸事宜心平气和处理，守其井水，供来往人用，为守旧之义。诸事宜防有变。',
    胎孕: '宜防有不成之兆。防产母有灾，作福祈保可平安。',
    子女: '儿女有顺良之象，宜防血光。',
    周转: '无望。再待时另调。',
    买卖: '尚可，但不可大作为。小交易有利。',
    疾病: '肾、膀胱、尿道等生殖器官之疾。',
    等人: '尽速再连络，迟来。',
    寻人: '东南或北方可寻。',
    失物: '在室内，可寻，但难寻。',
    旅行: '不宜。',
    讼词纠纷: '有三四人牵连。不动产之讼宜和解，有刑戮之象。慎之。',
    求事求职: '安於本份，换新的工作也是小工作。',
    考试: '尚可。',
    改行: '改行不利，守之尚可。开业者不宜，此卦有破损之象。',
  },
  革: {
    主要: '表该改革、革新之时候了！主是个状况卦，吉凶未定。事事情况虽不稳定、明朗，但只要有心改变，重新再来则成功机会大。一切不可固执不化、不变通。',
    特性: '性急，反传统，爱改变现象，创新事物，旅行，变换工作，口齿伶俐。',
    解释: '革旧布新。',
    运势: '不稳定，多变化之际，凡事均有所变动，故需弃腐朽而立新者，宜下决心改革。但仍须谨慎改革之道，善改则吉，恶改则凶。',
    爱情: '去者已矣。凡事均有新的开始，迎新为宜。',
    家运: '多事之秋。慎重改变自己的生活方式，方能建立新的气象。',
    胎孕: '胎安。儿女越年长越好，因其运格、际遇常有变动之故也。',
    子女: '子女与母亲有缘薄之象。',
    周转: '要即时改变方针。',
    买卖: '改变经营方式为宜。',
    疾病: '病情变化多端，须转换求医。患者为心脏、眼、口及呼吸系统之疾病，可能需要手术去除病灶才能安定。',
    等人: '因中途变卦不会来。',
    寻人: '速改变方向，向西、南方寻找。',
    失物: '遗失难寻。宜向西南或西方或许可以找到。',
    旅行: '可以。但会改变日期。',
    讼词纠纷: '宜和，有小人。以和顺态度处理可调。',
    求事求职: '不可守旧，改变职事有利之时机也。宜速把握良机。',
    考试: '越来越好。',
    改行: '改行大吉大利。开业亦吉。',
  },
  鼎: {
    主要: '表三足而立，三人而行，平稳之势。另表拜神、礼佛的香炉。主吉象。事业投资应与人合夥为佳，诸事可在平稳中发展。唯对感情、婚姻略有凶象，出现三人行的机率很高，有外遇出轨的迹象。只是大家会很理智的来寻求解决。',
    特性: '因人成事，得利於他人之助，易生多有长辈照顾提拔。交游广阔，喜欢热闹，人多之处更能表现其才能。',
    解释: '极为旺盛。',
    运势: '能解决困难，取得事物之稳定而名利双收，会有不错的成就，最好新找外人来共事、合夥。',
    爱情: '吉中带凶，外表平稳顺利，可能另有第三者的介入，且在暗中发展不易被发现，需小心或心里有准备。',
    家运: '生平幸福之运，吉也。',
    胎孕: '不安，不久便产。男女将来皆豪杰，母宜保。',
    子女: '子女均才能出众，将成大功立大业之趋势也。',
    周转: '可成。',
    买卖: '有大利可得。',
    疾病: '为消化器官肠的病症，应无大碍。需注意心脏之症、心火过旺。有变，但可无碍。',
    等人: '一定会来。',
    寻人: '南方或东南方。不必操心，会自己回来。',
    失物: '东南方寻。',
    旅行: '有意外收获，可行。',
    讼词纠纷: '此纠纷由小是非而起，宜和解。',
    求事求职: '大利。有上辈提拔。',
    考试: '登科上榜。',
    改行: '改行有得天独厚的条件，速进行。开业为吉利通达之象。',
  },
  震: {
    主要: '表奋发、震动有冲动不安稳的现象。六冲纯卦，主大好大坏之卦象。此卦要注意意外血光，有被惊吓之情形发生。运动比赛可为吉论。雷通常表示，声势浩大，却是有声无形虚象之意。与人交往最忌此卦，表面热心却只是虚应一番，无诚心，不能用真诚来对待，否则会很失望。',
    特性: '心直口快，喜怒形於色，缺乏耐性，工作不稳定，爱变化，理财能力差，自立自强。',
    解释: '受惊，害怕。',
    运势: '表面似盛泰，但正处於多事之秋，宜慎重，勿自视过高，无益也。有动荡、不安、惊惧之象耳。',
    爱情: '有虚无实，是非、外语杂多。',
    家运: '家中常有变动惊惧、不平等、多争吵，影响家运进展，宜审慎处理家务事也。',
    胎孕: '临产妇得之无碍。',
    子女: '子女多有性刚者，但皆争气，奋发，并有少年劳苦老来福之象，有成有福。',
    周转: '抱持可有可无之心态，因此卦有被反悔之象。',
    买卖: '不可有始无终，否则难成。',
    疾病: '所患是脑部、神经系统，或急性肝病、足部之病，病有不安之象，需注意意外血光。等人',
    寻人: '此人临时起意而出走，但亦会马上回来。',
    失物: '往东西两方寻可见，若不见则失矣。',
    旅行: '吉利。临时起意也。',
    讼词纠纷: '和劝反覆，但无大害。速调免祸，木字姓的人为贵人。',
    求事求职: '不利。',
    考试: '可榜登科甲。',
    改行: '改行吉利，为变劲之时机也。开业者可做策划进行。',
  },
  艮: {
    主要: '表停止、退守之意。六冲纯卦。主大好大坏之象。凡事应当知进退、量力而为。有如登山越岭般，需充分审视自己的体能，和山上的情势，绝不可强行逞能，适时的休息，方能平安度过。得此卦时应有大事或障碍、不顺阻挡在前，若能保守、修身养性则安，反则为凶。',
    特性: '耐性佳，保守经营，努力奋斗，老成持重，有偏财运，精於计画分析，处事有条理，公私分明，择善固执。',
    解释: '不动，自己改变。',
    运势: '应当洁身自爱，依赖心不要太重，否则不利。凡事不可轻举妄动，诸事宜守，相辅得吉。儒家有言:『静亦定，动亦定』，此非言死等，宜中正德行，固守贞常之道，凡事有定之理也。',
    爱情: '有对峙、单恋、难合之势，双方各有阻挠也。:『静亦定，动亦定』，此非言死等，宜中正德行，固守贞常之道，凡事有定之理也。',
    家运: '不和，家运停滞不发，改正自己以谋求开运之道，内有动荡、困境。',
    胎孕: '难产之虞。',
    子女: '子女多有不和、不相辅之数。',
    周转: '难成。',
    买卖: '有些小是非，失利之象，但可成。',
    疾病: '为鼻炎、口鼻之症，动脉血管硬化或胃胆之疾。难治。',
    等人: '不会来。',
    寻人: '西南方，难寻。',
    失物: '东北方可寻。',
    旅行: '不利。',
    讼词纠纷: '因小变大。宜速和解，否则有不利之象。',
    求事求职: '固守本份为宜。',
    考试: '落榜。',
    改行: '改行不利。开业不宜，须再待时。',
  },
  渐: {
    主要: '表循序渐进，不可心急之意。主吉象。好事慢慢在进行中，一切遵循正里常规即可，事业投资均能有收益。感情婚姻，更是结果收成欢喜结局之时。',
    特性: '感觉细腻，思想敏锐，重品味，讲情调之人，爱追求变化的事物，口才佳。反应快，为人热心，财运丰厚。',
    解释: '逐渐好转。',
    运势: '逐渐顺利，光明开运之象。凡事物务必掌握时机，循序渐进，可得吉庆。须防款项交易之差错及色情之灾。',
    爱情: '慢慢交往可成就良缘。但此卦有女子思男或已入男家，未能得男方家长同意者，但有心中能成良缘。',
    家运: '渐曙光明、幸福之象，诸事宜以顺乎自然为吉，反则有克也。',
    胎孕: '无碍。',
    子女: '儿女多坚强笃实，态度温顺，将来有成就。',
    周转: '不成亦勿放弃，久调可成。',
    买卖: '渐进有利，过急不利，欲速则不达。',
    疾病: '为胃肠、耳鼻之症，耐心治疗可渐癒。',
    等人: '迟到。',
    寻人: '在东南或东北二方，过些时日可寻得。',
    失物: '遗失可寻。',
    旅行: '可，但可能会误期。',
    讼词纠纷: '进则胜，退则输。买卖是非居多。',
    求事求职: '可寻得良好工作。',
    考试: '金榜题名。',
    改行: '改行吉利。开业吉利，渐有发展之象。',
  },
  归妹: {
    主要: '表小妹急着先出嫁，为感情冲动、不理智之象。主小吉带凶。得此卦投资、升迁、合夥，大概与男女之间的感情用事，会有很大的关连。感情，第三者积极介入之象，或是自己一厢情愿冲动的妄想。',
    特性: '具有同情心，喜欢结交朋友，爱热闹，贵人运多，恋爱运早，喜追求变化性工作，容易转换工作，多学不专。',
    解释: '游魂挂、精神恍惚。',
    运势: '祸出百端，事物有违常理。初时有悦，不久反凶，祸害随至。',
    爱情: '孽缘，必以悲剧收场。',
    家运: '外观风光幸福，其实家内正起风波，有失和、祸害等不幸情况。',
    胎孕: '无碍。秋占不利。',
    子女: '儿女有沉於情慾之中者。',
    周转: '希望渺小。',
    买卖: '表面上不错，其实亏了老本。',
    疾病: '为肝病、肿瘤、骨髓系统或脑溢血、手脚受伤。',
    等人: '不会来。',
    寻人: '在东方或西方。',
    失物: '监守自盗，内神通外鬼、盗走。',
    旅行: '不宜。',
    讼词纠纷: '有女人在内，事求分晓後，力持和解，不必要无谓闹事。',
    求事求职: '放弃算了，再另做打算。',
    考试: '有重新再读一年之况。',
    改行: '改行不宜。开业者宜暂停，静候时机。',
  },
  丰: {
    主要: '表丰收之象。主吉中带小凶之象。凡事积极奋发可成，有兴致高昂，一时天雷勾动地火，闪电迅速达成之意，此卦最利於短期投资理财，感情则可情投意合而速成。',
    特性: '随和谦虚，懒散但内心急躁，被动，拖延，须人催促，多偏财运或意外之好处，及异性之帮助。',
    解释: '丰富，丰收。',
    运势: '丰者必有所得，明智者宜於最全盛有收获时，保身明哲。然人多贪而不厌，於满足，而挺险图利，终有悔。尤其应注意有诉讼之事，有损财或火灾之象。',
    爱情: '炽情，好动，情可成。若得意忘形则有失。',
    家运: '繁华幸福，但为人处事宜守分寸为要。',
    胎孕: '无碍。',
    子女: '对子女宜多加教养，免招不幸。',
    周转: '耐心应对，则可在短时间内达成。利在寅午未申日。',
    买卖: '正直经营可获利。贪则有失。',
    疾病: '为急性病，需注意是心脏病或意外伤害所造成。神经系统可能受伤的疾病，小心手足骨折、残废。',
    等人: '会来。亦会因故而很快离去。',
    寻人: '此人会自觉不安而自己回来。',
    失物: '往东、南方寻可得。',
    旅行: '虽宜但有些波折。',
    求事求职: '有利。职务工作变动大，或外务之类。',
    考试: '及格有望。',
    改行: '改行不可三心二意。开业者吉利。',
  },
  旅: {
    主要: '表旅行、不定、不安稳之意。主小凶带小吉之象。旅行者常居无定所。表示事事皆在浮动之中，虽不现凶象，但也是很令人烦心的。投资理财、感情、婚姻，大慨都是游戏的心态吧！',
    特性: '一生多外出旅行运，喜欢多变化事物，重效率，享受，亦多桃花运，异性缘浓。兴趣广泛，爱尝试冒险。',
    解释: '在火山口旅游，非常危险。',
    运势: '诸事不定，宜考量他人之意见。洁身自爱，始可改变厄运，否则必有凶。',
    爱情: '不稳定、游戏心态重，宜以平常心看之，不能太重视。',
    家运: '内面不和，是非多，意见不一。家运衰也。',
    胎孕: '多是夜间产。',
    子女: '骨肉无情，不幸之兆。',
    周转: '小数目可，大数目不成。',
    买卖: '多碍难成，或交易不成。',
    疾病: '是心脏与消化系统脾胆之疾，心脏、手部之伤。有多病转移之象，宜速求医，拖延难治。',
    等人: '临时变意不会来。',
    寻人: '此人为情所困，意志消沉而远离也，难寻。',
    失物: '被盗走，找不回也。',
    旅行: '不宜。',
    讼词纠纷: '拖延时间不利，难解决。若和解不成，难脱伽锁之患。',
    求事求职: '白费心神。',
    考试: '很差。',
    改行: '改行不利。开业者不得时机。',
  },
  巽: {
    主要: '表不定之象，时柔顺、时狂暴。六冲纯卦，主大好大坏之象。不易控制的局面，包括人、事、感情、投资，都是令人很难去掌控的、会有波折和变化。',
    特性: '求知慾强，喜欢追求新事物，新潮流，多外出旅行运，朋友多，财运佳，异性缘重，相识满天下。',
    解释: '粗暴。',
    运势: '此卦象徵多波折，游离不定之运也。此时要处理不惊，坚定意志，物事果断而随机应变，不必一味跟从顺行。待人接物宜心平气和，则可得意外之收获。切记狂风之下必有所损。',
    爱情: '要冷静。风若和，日必丽。风若凝聚，可知暴风雨将至，终身大事不可不慎。时好时坏，把戏真不少，均轻飘不实也。',
    家运: '家内已生风波，遵照长辈意见与指示方可渡过难关，否则动荡不安。',
    胎孕: '有碍。宜慎。',
    子女: '养儿育女，可谓无微不至，但有孝顺恩情之子女，福也。',
    周转: '小吉，大则难调。',
    买卖: '好时机，绝对有利。',
    疾病: '内症是糖尿病或胃肠之症。病症在外表大是在皮肤病上，病情变化无常，宜长期调理。',
    等人: '主动约人，有结果。',
    寻人: '此人隐於附近朋友家中，好言相劝可回。大致是吵架出走，若是债务纠纷寻人者，注意发生冲突损伤。东南方位。',
    失物: '东南方可寻。混藏於其物品下面。',
    旅行: '可。',
    讼词纠纷: '不易和解，托地方人士调解，二人可调。',
    求事求职: '不易和解，托地方人士调解，二人可调。',
    考试: '宜多用功。',
    改行: '改行需看情况，但必有所变动。开业可行，但勿太急，慢慢进取有利。',
  },
  兑: {
    主要: '表少女纯真喜悦之象。却在纯真之中带有娇蛮、任性的态度。六冲卦象，大好大坏。忧喜参半！',
    特性: '细心，体贴，善解人意，口才佳，幽默感，宜从事公关，服务业。',
    解释: '喜悦，高兴。',
    运势: '有喜亦有忧，有誉亦有讥，虽得吉庆如意，然应守持正道，否则犯灾。',
    爱情: '有娇蛮、任性斗嘴之象，切勿意气用事，因此卦内刚，定会有口舌，随和相处可吉。有如两小无猜的纯纯感情般。',
    家运: '有和悦之气象，但要操守自律，行事不可越轨，有分寸可得吉运。若不操守自律，必犯色情之害而受殃耳。',
    胎孕: '孕安。能带给家人喜悦，又与六亲和睦，有缘。但也不要过份溺爱才是。',
    子女: '骨肉情深，和好幸福之象。',
    周转: '可顺利，不须急也。',
    买卖: '有反覆之象，然尽力必成，可得大利之交易。',
    疾病: '酒色之病，久则难癒。',
    等人: '会来，且有喜讯相告。',
    寻人: '很快可知其下落。向西方寻可得。',
    失物: '在西方，喜中有失，询老者可得。',
    旅行: '鼓励。',
    讼词纠纷: '非大事，有人解说有利。',
    求事求职: '得利，但亦不可太大意。',
    考试: '成绩佳。',
    改行: '改行吉利，开业吉利。',
  },
  涣: {
    主要: '表涣散之象。主凶象。心神不宁、精神不佳，人事向心力均已离散，有颓废不振的运势。事业、感情、婚姻根基开始有松动的情势产生。人的心绪、沈闷是最大的影响。若能稳定情绪、定下心神，应会再恢复以往的神采风光。',
    特性: '喜追求变化，缺乏耐性，工作不稳定，理财能力差，爱旅行外出，变换住所。对朋友热心，桃花运也多。',
    解释: '焕然一新，涣散。',
    运势: '初有小损，但终可解困而心悦，凡事小心则百事亨通，任性放纵必败。远方物事吉利，但宜守其常。',
    爱情: '因有一些小障碍，而可能会造成离散之象，外在因素，情缘仍在，有可能在复合。',
    家运: '起初有损，有波折且困，但可渐入佳境。',
    胎孕: '临产妇无碍。',
    子女: '双亲百般劳苦为子女，而子女深知父母养育教导之恩，多得良好配偶，并有才艺之象。',
    周转: '目标准确则有利，否则波折多亦难成。',
    买卖: '虽难成，客户会再回来，但忍耐些时日可有利。',
    疾病: '幼年体弱多病，多有逐年渐康复，但体质亦弱。病大致凶，须依病情之新旧论断。因此卦虽病不稳定，有险象，但亦有解。',
    等人: '不一定会来。',
    寻人: '难寻。相约者前者未定，後者可相寻，有信即可速动。',
    失物: '散失，难寻。得亦有损。',
    旅行: '吉。但勿近水。',
    讼词纠纷: '宜缓，待成见已散时，再调可成和解。',
    求事求职: '虽有挫折，但可成。宜耐心。',
    考试: '尚可，宜再加油。',
    改行: '择时视势而行，不必急於一时。开业可，初时有小挫折，勿惊。',
  },
  节: {
    主要: '表节制之象。主是个状况卦，吉凶未定之象。卦中所现，提醒卜卦之人，事事有过於放纵之象，尤其是在金钱方面，投资理财需节制有守为安。感情则要多留意自己的言行举止，已有过份之态或对对方过於放纵之势 。',
    特性: '理想高远，但与现实差距大，不过却有恒久的耐力，有志者是竟成。宽以待人，严以律己，社会声誉不错。',
    解释: '节制，节约。',
    运势: '以和悦之心去渡过险境，则可克难而出险道。而悦者何苦恼，陷者有悦象，故此时需要有节制为宜。',
    爱情: '是否成就，须看自己之节制之品德，因得此卦，若女者对感情坚贞而不随便者，可吉也，否则破。',
    家运: '谨守做人处事的规范，则可得平安和乐之家庭。',
    胎孕: '可求神保产母，否则有厄。',
    子女: '儿女多温柔孝顺。但若长辈行为不检反招破，得不偿失也。',
    周转: '远水难救近火。无望。',
    买卖: '不得时也。',
    疾病: '为肾脏系统或肺部系统等疾病，病情拖延有险象。',
    等人: '不来，或须久等。',
    寻人: '人说皆是虚言。有所藏身，不必去寻。想回来自己会回来。',
    失物: '西方或西北方，虽在近处，但难寻。',
    旅行: '不宜。',
    讼词纠纷: '此纠纷有拖拖拉拉之象，莫论谁是谁非，耐心处理必有人调解。',
    求事求职: '再待时机，成功率小。',
    考试: '须力求上进，不可怠忽。榜尾有名。',
    改行: '改行不宜。开业宜缓图，计划周详後方可。',
  },
  中孚: {
    主要: '表诚信、实在。主吉象。若为人处事一切以诚信为重，则事事皆可顺利而行。',
    特性: '心地善良，负同情心，朋友多助，为人风趣，口才佳，反应快，桃花运早。重罗曼蒂克情调，讲气氛感觉。',
    解释: '得人缘，孚众望。',
    运势: '如三月之春花，似锦大地，与人谋事均得利。诚者，立业之本也，若存邪曲之念则破吉运，须认清善恶。得此卦诚者佳，尤利他乡财路。',
    爱情: '双方以诚相待，良缘也。',
    家运: '有突发大利，平安幸福之吉象。',
    胎孕: '安。',
    子女: '父母慈且诚信，故子女必孝，忠信门第也。',
    周转: '不成问题。',
    买卖: '可成亦有利。',
    疾病: '病情大致凶，为胃肠、腹部或肾弱之症，宜妥善调理。',
    等人: '必定来。',
    寻人: '不寻自来。',
    失物: '遗失难寻，虽有消息亦虚耳。',
    旅行: '吉利。',
    讼词纠纷: '为安来之事，速处理无妨，迟则有伤。更防盗贼。',
    求事求职: '有利。',
    考试: '必定上榜。',
    改行: '改行可按照计划行事。开业者吉利，先苦後乐之象。',
  },
  小过: {
    主要: '表有志难伸、龙困浅滩，像犯了一个小的过错般，而被处罚。主凶象。事事小有不顺，有被打压的迹象，最忌冲动犯上，必因此而埋下祸端。需沈潜、忍耐一段时间才能再有发挥的机运。',
    特性: '谦虚，敬业，主动，积极，反应快，律己岩，不断吸收新知识，学习新事物。乐於助人，勇於认错。',
    解释: '稍有过失。',
    运势: '行事不如意，宜谨守，与六亲不和之象。又忠言逆耳犯上得罪人之事，反为害己之因，故凡诸事均有枝节、苦烦，不能达志耳。',
    爱情: '拥有过，但自觉不相称，已经不挂於心矣。',
    家运: '常生口角，有离家去外地之象。此卦名小过，故有因过而须离乡耳。',
    胎孕: '须谢灶神方可得安顺。',
    子女: '骨肉无情，兄弟不和，多劳苦之象耳。',
    周转: '小有望，大难成。',
    买卖: '小可，大不可。因过者必有错也。',
    疾病: '患手、足之患，审察之。病情虽有变恶之向，但治癒有望，不可掉以轻心。',
    等人: '不来，否则会迟到。',
    寻人: '只在原处逃避，当得见。如有债务之争，别找也罢，恐有过失之错。',
    失物: '可能遗失在水边，难现。',
    旅行: '不利。',
    讼词纠纷: '争端无益。宜和。',
    求事求职: '无指望。',
    考试: '不理想。',
    改行: '改行不宜。开业者宜再待时机，否则不利。',
  },
  既济: {
    主要: '表水火阴阳调和，一时平安和乐之象。主吉中带凶之象。水火本相克，因一时环境或人事所影响，而暂时相安无事，但终非常久之象，必有所冲克而败。',
    特性: '物质丰盛，但精神易空虚，外华内虚之感，交际广，知心少，心思细腻，多愁善感，喜宁静的生活。',
    解释: '已经得到利益不可贪之无厌。',
    运势: '功名双收。极盛既至，但须知物极必反，宜退守为吉，再进则凶。但得此卦者，皆有因一时顺利而忘本，大意失荆州，终有人不和、心迷乱之象。',
    爱情: '起初出双人对，形影不离，久後反无情必散。',
    家运: '生在富家之象，受祖上泽恩而不知珍惜者多，终而破运之兆。',
    胎孕: '不安。',
    子女: '初时幸福，长大後与双亲不和，慎之。',
    周转: '短期可成，长期无望。',
    买卖: '中途变卦不利。故初时见好就收，莫贪心拖延，否则不利。',
    疾病: '为心脏、腹部、肾脏等之疾病或老人疾病。有一时好转，随之病重，凶象也。',
    等人: '已中途折回，不必再等。',
    寻人: '若是常出走之人，小时自回，长大再出走，难寻。甚或不回。',
    失物: '寻得又失。同事人见可去寻堆积处，或勿再寻。',
    旅行: '宜近不宜远，否则有灾。',
    讼词纠纷: '有理变理亏，有虚惊，宜和解。',
    求事求职: '济急可，不宜良久。',
    考试: '实力有，但需视考期之日吉凶而定，小吉。',
    改行: '改行不宜。开业者最好慎重考虑，因此卦象好景不常。',
  },
  未济: {
    主要: '表阴阳不调和，气血不顺。主凶中带小吉之象。人事情绪不顺应是最大的影响主因。幸好此卦会有否极泰来之变化，一切均会雨过天清的。值的等待。',
    特性: '思想高远，又缺乏耐性，神经质，情绪不稳定，内心易有冲突感，孤独感，悲观，感性。宜往艺术哲学宗教发展。',
    解释: '快要获利。',
    运势: '气运不通，诸事不能如愿，有初衰後盛之数。凡事要有耐心去突破难关，前途将大有可望。此时宜采取笃实的方针和毅力，能够努力建立自己者，可名利双收。与人共谋事者，终有大利，可为之。',
    爱情: '刚开始时意见不能沟通，格格不入。若能试着去接受对方，可成也。',
    家运: '初运不振，耐心熬过辛苦的路，可得幸福。',
    胎孕: '平安。',
    子女: '子女迟得，但终有繁荣之象，将来都能振奋家声，以慰祖德。',
    周转: '有不少困难，成败各半。',
    买卖: '初限不佳，渐有好转之象。',
    疾病: '为下腹部与血液循环之症。',
    等人: '虽迟到，但有吉兆。',
    寻人: '负气出走，会自己回来。',
    失物: '在香火佛堂或水边不远处或混在某物中。北或南向可寻。',
    旅行: '有意外而影响行程，不宜远行。近可。',
    讼词纠纷: '就算要和解也煞费周章。拖些时日有贵人化解。',
    求事求职: '枉费心机，无希望，再等候时机。',
    考试: '不错，有好成绩。',
    改行: '改行可进行。开业吉。勿因初时不佳而灰心，生意会转兴隆。',
  },
};
